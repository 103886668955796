body {
  background: var(--bg);
  color: var(--text2);
}
a {
  color: inherit;
}

input {
  border: none;
  border-radius: 10px;
  background-color: var(--bgSub);
  font-size: 14px;
  padding: 4px 12px;
}

.absolute-b {
  position: absolute;
  bottom: 0;
}
.absolute-t {
  position: absolute;
  top: 0;
}
.absolute-l {
  position: absolute;
  left: 0;
}
.absolute-r {
  position: absolute;
  right: 0;
}

button {
  border: none;
  background: var(--sub);
  color: var(--sub_);
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 6px 12px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 15px;

  &.main {
    background: var(--main);
    color: var(--main_);
  }

  &.sub {
    background: var(--sub);
    color: var(--sub_);
  }

  &:hover {
    transform: scale(1.02);
    opacity: 0.95;
  }

  i,
  svg {
    margin-right: 8px;
  }

  // &:before {
  //   content: "";
  //   position: absolute;
  //   top: -5px;
  //   left: -5px;
  //   bottom: -5px;
  //   right: -5px;
  //   z-index: -1;
  //   filter: blur(20px);
  //   background: linear-gradient(90deg, var(--sub), var(--subDarkest));
  //   background-size: 400%;
  //   border-radius: 40px;
  //   transition: 0.5s;
  //   opacity: 0.2;
  //   animation: animate 40s linear infinite;
  // }

  // &:before {
  // }

  // @keyframes animate {
  //   0% {
  //     background-position: 0%;
  //   }
  //   100% {
  //     background-position: 400%;
  //   }
  // }
}

input[type="checkbox"] {
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 50% !important;
  overflow: hidden;
  border: none;

  /* On mouse-over, add a grey background color */
  & ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  &:checked ~ .checkmark {
    background-color: var(--main);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.container {
  padding: 0;
}
