.EventsListPage {
  padding: 8px;

  .content {
    min-height: 500px;

    overflow: hidden;

    > div {
      margin-bottom: 16px;
    }

    // &.loaded {
    //   animation: fadeIn linear 0.5s;
    // }
  }
}
