.ModernPage {
  height: 100vh;
  width: 100vw;
  overflow: hidden !important;

  padding: 0 !important; //Override Padding from .Page

  .TitleBox {
    transition: all 0.5s ease-in-out;
    animation: moveFontOut 0.5s linear;
  }

  .container {
    overflow: hidden !important;
  }
  .scroll {
    overflow: hidden;
    overflow-y: auto;
    flex: 1;
  }

  .header {
    padding: 8px;
    background-color: var(--bgSub);
    // position: fixed;
    // left: 0;
    // right: 0;
    // top: 0;

    .titleBox {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      i,
      svg {
        color: var(--main);
        padding: 8px;
        font-size: 20px;
      }
    }

    .Filter {
      //Wenn Filter als headerComponent übergeben werden
      background: var(--bg);
      color: var(--bg_);
      &.isActive {
        background: var(--sub);
        color: var(--sub_);
      }
    }
  }

  .children {
    padding: 8px;
  }

  .push {
    height: 64px;
    width: 1px;
  }
}

// @keyframes moveFontOut {
//   0% {
//   }
//   100% {
//     opacity: 0;
//     transform: translateX(-50vw) translateX();
//     color: var(--main);
//   }
// }
