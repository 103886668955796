.EventItem {
  position: relative;
  // margin-bottom: 0;

  .inner {
    overflow: visible;
    .imageBox {
      // margin: 100px;
      background-repeat: no-repeat;
      // background-image: url(/mask.png);
      width: 100%;

      .imgDiv {
        height: 70vh;
        border-radius: 12px;

        .cont {
          border-radius: 12px;
          overflow: hidden;
          // position: absolute;

          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          flex: 1;
          background-image: linear-gradient(-175deg, transparent, 70%, #000);

          // position: relative;

          //12 Dez
          .dateBox {
            background-color: #fff;
            color: #000;
            padding: 8px 12px;
            // height: 64px;
            // width: 64px;
            border-radius: 12px;
            position: absolute;
            top: 8px;
            right: 8px;

            .big {
              font-size: 16px;
              font-weight: 600;
            }
            .small {
              font-size: 10px;
            }
          }

          .title {
            font-size: 18px;
            font-weight: 700;
            color: #fff;
          }
          .subInfos {
            font-size: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
              margin-right: 8px;
              color: #fff;
              display: flex;
              flex-direction: row;
              align-items: center;

              i,
              svg {
                margin-top: -1px;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
  }
}
