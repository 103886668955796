.CityPage {
  .cityHeaderBox {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    height: 20px;
    .cityHeading {
      margin-right: 6px;
      font-size: 13px;
      font-weight: 500;
      background: var(--bgSub);
      transition: 0.2s;
      padding: 4px 12px;
      border-radius: 12px;

      &.active {
        background: var(--main);
        color: var(--main_);
      }
    }
  }
  button {
    font-size: 12px;
    padding: 4px 8px;
  }

  .TileMenu {
    margin-bottom: 32px;

    .LongTile {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 8px;

      span.title {
        font-size: 14px;
        max-lines: 1;
      }

      .iconBox {
        background: var(--sub);
        width: 32px;
        height: 32px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        svg,
        i {
          color: var(--sub_);
        }
      }
    }
  }
}
