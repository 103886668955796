.PrivacyPage {
  h1 {
    font-size: 32px;
    margin-bottom: 24px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    margin-bottom: 32px;
  }
}
