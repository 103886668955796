.TileItemLong {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  padding-bottom: 2px;
  margin-bottom: 2px;
  border-bottom: 1px solid var(--text5);

  .iconBox {
    // background: var(--bgSub);
    width: 44px;
    height: 44px;
    border-radius: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-right: 0px;

    i,
    svg {
      color: var(--sub);
      font-size: 20px;
    }
  }

  span.title {
    color: var(--main);
  }
}
