.mittelhessen_blue.dark-theme img {
  filter: brightness(0.8) contrast(1.2);
}

.mittelhessen_blue,
body {
  // --bgMid: #f1f0f5;
  // --bgUp: #fff;
  // --bgDown: #ebebeb;

  --statusBar: var(--subDarker);
  --statusBar_: #fff;
  --menuTop: var(--statusBar);
  --menuBottom: #e8eef5;

  --bg: #fff;
  --bgSub: #eaeff3;

  --black: #000;
  --white: #fff;
  --blue: #4a67e4;
  --blue_: #fff;
  --red: #d93636;
  --blue_: #fff;
  --yellow: #e3dc27;
  --yellow_: #000;
  --green: #189000;
  --green_: #fff;

  --bgColored: #2935bd;
  --bgColored_: #fff;

  --text1: #1a1a18;
  --text2: #444;
  --text3: #777;
  --text4: #aaa;
  --text5: #eee;

  --main: #28282b;
  --main_: #fff;
  --sub: #4a68f1;
  --subLight: #f9deec;
  --subLighter: #4a67e4;
  --subDarker: #1d289e;
  --subBlur: #2935bd6f;
  --sub_: #fff;

  --fa-primary-color: var(--main);
  --fa-secondary-color: var(--sub);
  --fa-secondary-opacity: 0.5;
}

@media (prefers-color-scheme: dark) {
  // img {
  //   filter: brightness(0.8) contrast(1.2);
  // }

  .mittelhessen_blue {
    --statusBar: #000;
    --menuTop: var(--statusBar);
    --menuBottom: #1a262f;

    --bg: #0f171d;
    --bgSub: #1a262f;

    --black: #fff;
    --white: #000;
    --blue: #1b2245;
    --blue_: #fff;
    --red: #d93636;
    --blue_: #fff;

    --bgColored: #2935bd;
    --bgColored_: #fff;

    --text1: #fff;
    --text2: rgb(190, 187, 187);
    --text3: #777;
    --text4: #444;
    --text5: #1a1a18;

    --main: #fff;
    --main_: #1a1a18;
    --sub: #4a68f1;
    --subLight: #f9deec;
    --subLighter: #4a67e4;
    --subDarker: #1d289e;
    --subBlur: #2935bd6f;
    --sub_: #fff;

    --fa-primary-color: var(--main);
    --fa-secondary-color: var(--sub);
    --fa-secondary-opacity: 0.5;
  }
}
