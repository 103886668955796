.WelcomePage {
  background-color: var(--bg);

  * {
    // color: var(--sub_);
    color: var(--text1);
  }

  .circles {
    position: relative;
    height: 100vh;

    .c1 {
      position: absolute;
      background-color: var(--black);
      opacity: 0.1;
      filter: blur(32px);
      height: 70vh;
      width: 70vh;
      border-radius: 50%;
      left: -25vh;
      bottom: -25vh;
    }
    .c2 {
      position: absolute;
      background-color: var(--black);
      opacity: 0.4;
      filter: blur(80px);
      height: 50vh;
      width: 50vh;
      border-radius: 50%;
      top: -12vh;
      right: -25vh;
    }

    .cont {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 32px;

      p {
        // text-shadow: 0 0 0.3px var(--black);
      }
      a {
        color: var(--blue);
      }
      h1 {
        font-size: 32px;
        text-shadow: 0 0 5px var(--black);
      }
      .subTitle {
        font-size: 18px;
        font-weight: 500;
        text-shadow: 0 0 3px var(--black);
      }

      button {
        background: var(--sub);
        opacity: 1;
        font-weight: 600;
        color: var(--sub_);

        i,
        svg {
          font-size: 18px;
        }
      }

      .moreOptions {
        font-size: 12px;
        color: var(--text3);
        padding: 4px 8px;
      }
    }
  }
}
