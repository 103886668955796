.GhostLoader {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: var(--text5);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@media (prefers-color-scheme: dark) {
  .GhostLoader {
    &::after {
      background-image: linear-gradient(
        90deg,
        rgba(#222, 0) 0,
        rgba(#222, 0.2) 20%,
        rgba(#222, 0.5) 60%,
        rgba(#222, 0)
      );
    }
  }
}
