.ButtonBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .ButtonBarItem {
    .Icon {
      padding-right: 8px;
    }
  }
}
