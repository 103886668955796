.BottomNavigation {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-bottom: -1px; //workaround iPhone SE on Chrome

  .BottomNavigationItem {
    flex: 1;
    padding: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;

    i,
    svg {
      font-size: 24px;
      color: var(--text3);
      transition: 0.5s;
    }
    small {
      margin-top: 6px;
      font-size: 10px;
      transition: 0.5s;

      color: var(--text3);
    }

    touch-action: pinch-zoom;

    &:active {
      i,
      svg {
        transition: 0.5s;
        transform: scale(1.5);
      }
      span {
        transition: 0.5s;
        transform: scale(1.5);
      }
    }

    &.active {
      i,
      svg {
        transition: 0.1s;
        color: var(--text1);
      }
      small {
        margin-top: 6px;
        font-size: 10px;
        color: var(--text1);
      }
    }
  }
}
