.ImageListContainer.container {
  overflow-x: auto !important;
}

.ImageList {
  margin: -12px; //Ausglich vom Padding um die Bilder
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  max-width: 100vw;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  .ImageItem {
    transition: 0.2s;

    button {
      margin: 6px;
      padding: 0;
      background: transparent;
      img {
        border-radius: 12px;
        transition: 0.4s;
      }
      &:hover {
        img {
          cursor: zoom-in;
          transition: 0.2s;
          transform: scale(1.01);
        }
      }
    }

    &.true {
      transition: 0.4s;

      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      backdrop-filter: blur(16px);

      display: flex;
      align-items: center;
      justify-content: center;

      padding: 32px;
      button {
        img {
          width: 100%;
        }
        &:hover {
          img {
            cursor: zoom-out;
            transform: scale(1);
          }
        }
      }
    }
  }
}
