a {
  -webkit-touch-callout: none;
  cursor: pointer;
}
a:active {
  background-color: transparent;
}

* {
  -webkit-touch-callout: none;
  // touch-action: none; -> Scroll geht kaputt

  // pointer-events: none;
}

.container {
  max-width: 700px;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

span {
  display: block;
  &.dd {
    display: inline-block;
    font-weight: 700;
    color: blue !important;
    > span {
      display: inline-block;
      color: red !important;
    }
  }
}
.App {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  background: var(--bg);
  height: 100vh;
  width: 100vw;
  max-width: 100vw;

  display: grid;
  grid-template-rows: auto 1fr auto;

  .Page {
    padding: 8px;

    max-width: 100vw;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;

    .PageInner {
      // overflow-x: scroll;
      margin-left: -20px;
      width: calc(100% + 40px);
      padding-left: 20px;
      padding-right: 20px;

      // border: 1px solid red;
    }
  }

  header {
    z-index: 1000;
    width: 100%;

    background: var(--menuTop);
    border-radius: 0 0 20px 20px;
  }
  .mainDiv {
    justify-self: stretch;

    height: 100vh;
    width: 100vw;

    display: flex;
    flex: 1;
    .pages {
      display: flex;
      flex: 1;
      > div {
        animation: fadeIn 0.35s 1;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 100vw;
      }
    }
    .footerPush {
      height: 128px;
      width: 1px;
    }
  }

  footer {
    // border-radius: 12px 12px 0 0;

    position: fixed;
    z-index: 1000;
    bottom: -1px;
    left: 0;
    right: 0;

    background: var(--menuBottom);
    backdrop-filter: blur(4px);

    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: ;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(1.5) translateY(-10%) rotate(-3deg);
    opacity: 0.9;
  }
  100% {
  }
}
