.TileItemSquare {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 20%;
  padding: 4px 8px;

  .iconBox {
    background: var(--bgSub);
    width: 100%;
    height: 52px;
    border-radius: 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    i,
    svg {
      font-size: 26px;
    }
  }

  span.title {
    font-size: 10px;
    max-width: 15vw;
    overflow: hidden;
  }
}
