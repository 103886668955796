.BorderRadiusBox {
  padding: 0px;
  box-shadow: 0 0 12px 0 var(--text5);
  border-radius: 16px;
  margin-bottom: 16px;

  .inner {
    border-radius: 12px;
    overflow: hidden;
    background: var(--bgSub);
  }
}
