.OpeningTimes {
  .RenderHours {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px;

    span.closed {
      color: var(--dpRed);
      font-size: 18px;
      font-weight: 500;
    }

    span.dayName {
      width: 42px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      color: var(--text2);
    }

    .TimeSpanItem {
      color: var(--text3);
      font-weight: 300;
      font-size: 18px;
    }
  }
}
