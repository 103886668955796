.LocationView {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;

  .DefaultMap {
    flex: 1;
    width: 100%;

    * {
      cursor: pointer !important;
    }
  }

  button {
    i,
    svg {
      margin: 0;
      margin-left: 4px;
    }
  }
}
