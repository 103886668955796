.EntryPage {
  .container {
    max-width: 800px;
  }

  span.address {
    font-size: 16px;
  }

  .title {
    font-size: 24px;
    color: var(--main);
    font-weight: 600;
    text-align: center;
  }
  .subTitle {
    color: var(--sub);
    font-size: 18px;
  }
}
