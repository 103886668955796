.TileItemModern {
  display: flex;
  flex-direction: column;

  width: 50%;
  padding: 4px 8px;

  .iconBox {
    height: 80px;
    background: var(--black);
    width: 100%;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    i,
    svg {
      text-align: end;
      font-size: 32px;
      padding: 8px;

      color: var(--white);
      --fa-primary-color: var(--white);
      --fa-secondary-color: var(--black);
      --fa-secondary-opacity: 0.3;
    }
  }

  span.title {
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    overflow: hidden;
    padding: 12px;
    word-wrap: break-word;
  }

  &:nth-child(odd) {
    padding-right: 0;
  }
  &:nth-child(even) {
    padding-right: 0;
  }
}
