.ApplePage {
  height: 100vh;
  overflow-y: scroll;
  max-height: 100vh;

  max-width: 100vw;
  overflow: hidden;

  .TitleBox {
    transition: all 0.5s ease-in-out;
    animation: moveFontOut 0.5s linear;
  }

  .hidden {
    transform: translateX(25%) translateY(-50px);
    color: var(--main);
    opacity: 0;
    font-size: 10px;
  }

  :first-child {
    .FilterBar {
      margin-bottom: 12px;
    }
  }

  .Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    transition: 0.5s;
    padding: 8px 12px;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .subComponent {
      max-height: 0;
      overflow: hidden;
      transition: 0.4s;

      input {
        background: var(--white);
        color: var(--black);
        font-weight: 500;
      }
    }

    .title {
      //   display: block;
      //   flex: 1;
      opacity: 0;
      font-size: 15px;
      color: var(--main);
      font-weight: 600;
      z-index: 101;
    }
    a {
      font-size: 13px;
      color: var(--sub);
      width: 25vw;
      overflow-x: visible;

      &:first-of-type {
        text-align: start;
      }
      &:last-of-type {
        text-align: end;
      }

      &.navigateBack {
        color: var(--red);
      }
    }
  }

  &.scroll {
    .Header {
      background: var(--menuBottom);
      backdrop-filter: blur(4px);
      transition: 0.5s;
      z-index: 100;
      .title {
        opacity: 1;
      }

      .subComponent {
        max-height: 100%;
        transition: 0.4s;
      }
    }
  }

  .push {
    height: 64px;
    width: 1px;
  }
}

// @keyframes moveFontOut {
//   0% {
//   }
//   100% {
//     opacity: 0;
//     transform: translateX(-50vw) translateX();
//     color: var(--main);
//   }
// }
