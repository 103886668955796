.TrashPage {
  .TrashDateItem {
    background: var(--bgSub);
    border-radius: 12px;
    padding: 8px 8px;

    display: flex;
    flex-direction: row;
    margin-bottom: 4px;

    .title {
      font-size: 12px;
      color: var(--text2);
    }

    color: var(--main);

    .colorDot {
      margin-right: 8px;
      height: 44px;
      width: 24px;
      border-radius: 9px;
      background-color: var(--text4);
      border: 0px solid var(--text1);
    }
  }

  a {
    color: var(--text2) !important;
  }
}
