.MapPage {
  .LiveMap {
    flex: 1;
  }

  input {
    border-radius: 8px;
    padding: 8px 12px;
    font-weight: 500;
    background: var(--text1);
    color: var(--bg);
  }
}
