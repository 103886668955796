.AuthorBox {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // padding: 0 8px;
  // padding-top: 8px;

  position: relative;

  .Banner {
    img {
      width: 100%;
    }
    z-index: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;

    display: flex;
    align-items: center;
    .FollowButton {
      font-size: 12px;
      padding: 4px 8px;
      border-radius: 10px;
    }
  }

  img.pb {
    margin-right: 8px;
    height: 28px;
    width: 28px;
    overflow: hidden;
    border-radius: 12px;
    background: var(--bg);
  }
  span.name {
    font-size: 12px;
    font-weight: 500;
    margin-right: 6px;
    color: var(--text2);
  }
  span.date {
    font-size: 12px;
    color: var(--text3);
  }
}
