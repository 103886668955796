.DefaultMap {
  flex: 1;

  .mapboxgl-map {
  }

  > div {
    border: 2px solid var(--bgSub);
    border-radius: 12px;
  }

  // min-height: 120px;
  // width: 100%;
  // display: flex;
  // .map-container {
  //   flex: 1;
  //   height: 300px;
  //   border: 2px solid var(--bgSub);
  //   border-radius: 12px;

  //   canvas {
  //     border-radius: 12px;
  //     height: 100%;
  //   }

  //   > div {
  //     height: 100%;
  //   }
  // }
}
