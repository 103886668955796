.TrashBanner {
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 12px;

  button {
    width: 100%;
    border-radius: 0;
    background: var(--sub);
    color: var(--sub_);
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    margin: 0;

    i,
    svg {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
