.PostItem {
  display: block;
  border-radius: 12px;
  overflow: hidden;

  .titleImage {
    // border-radius: 12px;
    // margin-bottom: -16px;
    // position: relative;
    z-index: 100;
  }
  .AuthorBox {
  }
  .content {
    padding: 16px;

    .postContent {
      img {
        border-radius: 12px;
        margin-bottom: 12px;
      }
      .reading-box {
        border: none !important;
      }

      * {
        height: auto !important;
        max-width: 100%;
        background: transparent !important;
      }
    }
  }

  span.date {
    font-size: 12px;
    opacity: 0.3;
    color: var(--text2);
  }

  img {
    width: 100%;
  }
}
