.PagingListRender {
  .infinite-scroll-component__outerdiv {
    overflow: scroll;
    .infinite-scroll-component {
      max-height: 100%;
    }
  }
  .InfiniteScroll {
  }
}
