* {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 24px;
  font-weight: 800;
  color: var(--sub);
  margin-bottom: 4px;

  b {
    font-weight: 900;
    color: var(--text1);
  }
}
h2 {
  font-size: 24px;
  font-weight: 700;
  color: var(--main);
  font-weight: 800;
  margin-bottom: 12px;

  b {
    color: var(--text1);
  }

  small {
    font-size: 20px;
    color: var(--text2);
  }
}
h3 {
  font-size: 24px;
  font-weight: 500;
  color: var(--main);
}
h4 {
  font-size: 18px;
  font-weight: 600;
  color: var(--main);
}
h5,
h6 {
  color: var(
    --main
  ); //Für externe websites deren Design angepasst wird (zB News -> Theo Koch Schule)
}
@media (max-width: 992px) {
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 20px;
  }
}

p {
  font-size: 14px;
  font-weight: 300;
  color: var(--text2);
}
b,
b * {
  font-weight: 800 !important;
}
