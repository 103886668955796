.EntryItem {
  padding-bottom: 16px;
  margin-bottom: 10px;
  padding-top: 16px;
  border-top: 1px solid var(--text5);

  .title {
    color: var(--main);
    font-size: 18px;
    font-weight: 800;
  }
  .subtitle {
    color: var(--blue);
    font-size: 14px;
  }
  .slug {
    font-weight: 500;
    color: var(--blue);
    font-size: 14px;
  }

  .RenderImages {
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    // padding: 1px;
    img {
      margin-right: 8px;
      max-width: 30%;
      //   border: 1px solid var(--text5);
      box-shadow: 0 0 0 1px var(--text5);
    }
  }
}
