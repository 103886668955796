.ServerErrorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;
  text-align: center;

  padding: 32px;

  i,
  svg {
    font-size: 64px;
    color: var(--red);
    margin-bottom: 16px;
  }

  h1 {
    color: var(--red);
    margin-bottom: 8px;
  }

  p {
  }

  > * {
    transition: 0.4s;

    &:hover {
      transform: scale(1.05);
      transition: 0.4s;
    }
  }
}
