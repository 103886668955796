.FilterBar {
  .Filter {
    background: var(--text4);
    color: var(--text1);
    font-size: 13px;

    &.isActive {
      background: var(--sub);
      color: var(--sub_);
      transition: 0.4s;
    }

    transition: 0.4s;
  }
  .RemoveFilter {
    transition: 0.4s;

    i,
    svg {
      margin-right: 0;
    }
  }
}
